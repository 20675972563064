<template>
  <div>
    <NavigationBar titel="Benutzer einladen" showBackBtn></NavigationBar>
    <section>
      <template>
        <v-container fluid>
          <v-row justify="center" align="center">
            <v-col cols="12">
              <v-stepper v-model="steps" vertical>
                <v-stepper-step :complete="steps > 1" step="1">
                  Einladen via E-Mail
                </v-stepper-step>
                <v-stepper-content step="1">
                  <v-card flat>
                    <v-card-text>
                      <v-container fluid>
                        <v-row>
                          <v-col cols="12" sm="12" md="12">
                            <p class="subtitle-1">
                              Gebe die E-Mail-Adressen der Personen ein, die zu
                              dieser Organisation eingeladen werden sollen. Für
                              jede Person wird ein individueller
                              Zugangsschlüssel generiert. E-Mail-Verteiler
                              können daher nicht verwendet werden.
                            </p>
                          </v-col>
                          <v-col cols="12" sm="12" md="12">
                            <v-textarea
                              counter
                              no-resize
                              rows="7"
                              label="E-Mail-Adressen"
                              persistent-hint
                              hint="Verwende für jede E-Mail-Adresse eine neue Zeile."
                              clearable
                              outlined
                              v-model.trim="emails"
                            ></v-textarea>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="primary"
                        depressed
                        @click="setInvites()"
                        :disabled="!!!emails"
                      >
                        Weiter
                        <v-icon right>mdi-chevron-right</v-icon>
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-stepper-content>
                <v-stepper-step :complete="steps > 2" step="2">
                  Zuweisen von Daten
                </v-stepper-step>
                <v-stepper-content step="2">
                  <v-card flat>
                    <v-card-text>
                      <v-form
                        v-model="formIsValid"
                        @submit.prevent="sendInvites"
                      >
                        <v-container fluid>
                          <v-row>
                            <v-col cols="12" sm="12" md="12">
                              <v-data-iterator
                                :items="invites"
                                :search="search"
                                :sort-by="sortBy.toLowerCase()"
                                :sort-desc="sortDesc"
                              >
                                <template v-slot:header>
                                  <v-toolbar outlined flat rounded class="mb-4">
                                    <v-text-field
                                      v-model="search"
                                      clearable
                                      flat
                                      outlined
                                      dense
                                      hide-details
                                      prepend-inner-icon="mdi-magnify"
                                      label="Einladungen durchsuchen"
                                    ></v-text-field>
                                  </v-toolbar>
                                </template>
                                <template v-slot:default="props">
                                  <v-row>
                                    <v-col
                                      v-for="(item, index) in props.items"
                                      :key="index"
                                      cols="12"
                                    >
                                      <v-card outlined>
                                        <v-card-actions class="px-4">
                                          <v-spacer></v-spacer>
                                          <v-btn
                                            text
                                            color="error"
                                            @click="remove(item)"
                                          >
                                            <v-icon left>mdi-delete</v-icon>
                                            Löschen
                                          </v-btn>
                                        </v-card-actions>
                                        <v-divider></v-divider>
                                        <v-card-text>
                                          <v-row>
                                            <v-col cols="12" sm="4">
                                              <v-text-field
                                                label="E-Mail-Adresse"
                                                hint="An diese E-Mail-Adresse wird die Einladung gesendet."
                                                :rules="[
                                                  rules.required,
                                                  rules.email,
                                                ]"
                                                type="email"
                                                required
                                                persistent-hint
                                                outlined
                                                v-model.trim="item.email"
                                              ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="4">
                                              <v-autocomplete
                                                v-model="item.group"
                                                :items="userGroupsFiltered"
                                                item-text="title"
                                                item-value="meta.id"
                                                return-object
                                                hint="Benutzergruppen bestimmen grundlegende Berechtigungen. Erweiterte Berechtigungen können nach Erstellen des Kontos zugewiesen werden."
                                                required
                                                :rules="[rules.required]"
                                                persistent-hint
                                                outlined
                                                chips
                                                small-chips
                                                label="Benutzergruppe auswählen"
                                              ></v-autocomplete>
                                            </v-col>
                                            <v-col cols="12" sm="4">
                                              <v-select
                                                v-model="item.license"
                                                :items="products"
                                                item-text="title"
                                                item-value="id"
                                                item-key="id"
                                                return-object
                                                hint="_"
                                                required
                                                :rules="[rules.required]"
                                                persistent-hint
                                                outlined
                                                chips
                                                small-chips
                                                label="Lizenz auswählen"
                                              >
                                                <template v-slot:message>
                                                  Lizenzen bestimmen die
                                                  Funktionalität, die ein
                                                  Benutzer innerhalb der
                                                  Organisation nutzen kann. Die
                                                  Auswahl der Lizenz kann Kosten
                                                  verursachen. Eine detaillierte
                                                  Auflistung über anfallende
                                                  Kosten ist in unserem
                                                  <a
                                                    href="#"
                                                    class="text-decoration-none"
                                                    >Preis- und
                                                    Leistungsverzeichnis<v-icon
                                                      x-small
                                                      class="ml-1"
                                                      color="primary"
                                                      >mdi-open-in-new</v-icon
                                                    ></a
                                                  >
                                                  definiert.
                                                </template>
                                              </v-select>
                                            </v-col>
                                          </v-row>
                                        </v-card-text>
                                      </v-card>
                                    </v-col>
                                  </v-row>
                                </template>
                              </v-data-iterator>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-form>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn depressed @click="steps = 1"> Zurück </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn
                        depressed
                        color="primary"
                        :disabled="!formIsValid"
                        @click="steps = 3"
                      >
                        Weiter
                        <v-icon right>mdi-chevron-right</v-icon>
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-stepper-content>
                <v-stepper-step :complete="steps > 3" step="3">
                  Vorgang bestätigen
                </v-stepper-step>
                <v-stepper-content step="3">
                  <v-card flat>
                    <v-card-text>
                      <v-container fluid>
                        <v-row dense>
                          <v-col cols="12" sm="12" md="12">
                            <v-alert text color="primary" type="info">
                              Benutzer müssen die Einladung erst annehmen, bevor
                              sie innerhalb der Organisation verwendet werden
                              können.
                            </v-alert>
                          </v-col>
                          <v-col cols="12" sm="12" md="12">
                            <v-alert
                              text
                              icon="mdi-account-credit-card"
                              color="primary"
                            >
                              Neue Benutzerkonten können Kosten entsprechend der
                              ausgewählten Lizenz verursachen, die entsprechend
                              in Rechnung gestellt werden. Prüfe Deine Eingaben
                              oder kontaktiere den Support bei Rückfragen.
                            </v-alert>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn depressed :disabled="loading" @click="steps = 2">
                        Zurück
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn
                        depressed
                        color="primary"
                        :loading="loading"
                        @click="sendInvites()"
                      >
                        Benutzer kostenpflichtig einladen
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-stepper-content>
              </v-stepper>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
  </div>
</template>

<script>
import { db, auth, FieldValue } from "@/firebase";
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import { ADMIN } from "@/store/modules.js";
import { GET_USER_GROUPS } from "@/store/action-types.js";

export default {
  name: "admin-users-tab-accounts-invite-new",
  components: {
    NavigationBar,
  },
  data() {
    return {
      search: "",
      filter: {},
      sortDesc: false,
      sortBy: "title",

      emails: "",
      steps: 1,
      formIsValid: true,
      valid: true,
      loading: false,

      invites: [],
      products: [],

      // Rules
      rules: {
        required: (value) => !!value || "Erforderlich.",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Ungültige E-Mail-Adresse.";
        },
      },
    };
  },
  computed: {
    userGroups() {
      return this.$store.state.admin.userGroups;
    },
    userGroupsFiltered() {
      const input = this.$store.state.admin.userGroups;

      const filtered = input.map((item) => {
        const container = {};
        container.title = item.title;
        container.id = item.meta.id;
        return container;
      });

      return filtered;
    },
    defaultUserGroupId() {
      return this.$store.state.organization.activeOrganization.config.security
        .defaultUserGroupId;
    },
    emailsArray() {
      var array = this.emails.split(/\n/).filter((item) => item);
      var uniqueArray = array.filter(function (elem, index, self) {
        return index === self.indexOf(elem);
      });
      return uniqueArray;
    },
    defaultProduct() {
      return this.products.length > 0
        ? this.products[0]
        : { title: "", id: "" };
    },
    invitesObject() {
      var array = this.emailsArray;
      var element = this.userGroups.find(
        (item) => item.meta.id === this.defaultUserGroupId
      );
      var output = [];
      array.forEach((item, index) => {
        output[index] = {
          email: item,
          group: {
            id: element.meta.id,
            title: element.title,
          },
          license: {
            id: this.defaultProduct.id,
            title: this.defaultProduct.title,
          },
        };
      });
      return output;
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.$store.dispatch(`${ADMIN}${GET_USER_GROUPS}`, {
        organizationId: this.$route.params.organizationId,
      });
      this.getProducts();
    },
    getProducts() {
      // get products from firestore "products" collection
      db.collection("products")
        .where("active", "==", true)
        .where("userVisible", "==", true)
        .where("userSelectable", "==", true)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const data = doc.data();
            this.products.push({ title: data.name, id: doc.id });
          });
        });
    },
    changeRoute() {
      this.$router.push({
        name: "admin-users-tab-accounts",
        params: { organizationId: this.$route.params.organizationId },
      });
    },
    setInvites() {
      this.invites = this.invitesObject;
      this.steps = 2;
    },
    test() {
      for (let i = 0; i < this.invitesObject.length; i++) {
        console.log(this.invitesObject[i].email);
      }
    },
    remove(i) {
      this.invites.splice(this.invites.indexOf(i), 1);
    },
    sendInvites() {
      this.loading = true;

      if (this.formIsValid && this.invitesObject !== null) {
        console.log(this.invitesObject);
        var batch = db.batch();
        // for (const object of this.dataObject) {
        //   return object
        // }
        for (let i = 0; i < this.invitesObject.length; i++) {
          const docRef = db
            .collection("organizations")
            .doc(this.$route.params.organizationId)
            .collection("invites")
            .doc();

          batch.set(docRef, {
            email: this.invitesObject[i].email,
            group: {
              title: this.invitesObject[i].group.title,
              id: this.invitesObject[i].group.id,
            },
            license: {
              title: this.invitesObject[i].license.title,
              id: this.invitesObject[i].license.id,
            },
            invite: {
              key: "",
              status: "submitted", // submitted, waiting for processing, sent waiting ,
              type: "mail", // mail, link
            },
            meta: {
              id: docRef.id,
              createdBy: auth.currentUser.uid,
              createdAt: FieldValue.serverTimestamp(),
            },
          });
        }
        // this.invitesObject.forEach(() => {});
        batch
          .commit()
          .then(function () {
            return console.log("done");
          })
          .then(setTimeout(this.changeRoute, 2000));
      } else {
        alert("Prüfen Deine Eingabe und versuchen es erneut.");
      }
    },
  },
};
</script>

<style></style>
